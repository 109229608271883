
























import { Component, Vue, Prop } from 'vue-property-decorator';
import { H3Icon } from '@authine/awesome-ui';
import Enterprise from '../../components/login/enterprise.vue';
import { EnterpriseService } from '../../api';

interface setEnterprise {
  enterpriseLogo: string;
  enterpriseName: string;
  createTime: string | undefined;
}

enum pageStates {
  // 新增
  AddPage = 'add-page',
  // 编辑
  EditPage = 'edit-page',
  // 详情
  DetailsPage = 'details-page',
}

@Component({
  name: 'SetEnterprise',
  components: {
    AIcon: H3Icon,
    Enterprise,
  },
})
export default class SetEnterprise extends Vue {
  @Prop() pageState!: pageStates;

  form: setEnterprise = {
    enterpriseLogo: '',
    enterpriseName: '',
    createTime: undefined,
  };

  created () {
    // 检测是否需要初始化企业
    this.checkInitEnterprise();
  }

  async checkInitEnterprise () {
    const { success, data } = await EnterpriseService.checkEnterpriseExist();
    if (!success) return;
    if (data) this.goPlatformHome();
  }

  goPlatformHome () {
  }
}
